import React, {useEffect} from "react"
import { Link } from "gatsby"

import {deleteAllCookies, useOptOut} from "../../utils/analyticsDoNotTrack"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import '../subcribePage.scss'
import "normalize.css/normalize.css"
import logo from "../../images/BM_logos.gif"


const IndexPage = () => {
  useEffect(() => {
    deleteAllCookies()
    useOptOut()
  });
  return (<Layout>
    <SEO title="BAD MANNERS" />
    <div className="grid-container">
      <div className="image-wrapper">
        <img src={logo} className="image" alt="BAD MANNERS"/>
      </div>
      <div className="button-wrapper">
        <OutboundLink href="https://subscribe.newsletter2go.com/?n2g=yg969par-m69pb1s3-6ud">
          <button>
            subscribe
          </button>
        </OutboundLink>
      </div>
      <div className="bottom">
        <Link to="/do-not-track/dataSecurity/">Data security</Link>
        <Link to="/do-not-track/imprint/">Imprint</Link>
      </div>
    </div>
  </Layout>
  )}

export default IndexPage
